import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RegisterYourInterest from '../components/register-your-interest'

import RenderBlock from '../utils/render-block'

class ProjectTemplate extends Component {

  state = {
    formActive: false,
    isMobile: false
  }

  throttledHandleWindowResize = () => {
    if (typeof window !== 'undefined') {
      this.setState({ isMobile: window.innerWidth < 924 })
    }
  }

  componentDidMount() {
    this.throttledHandleWindowResize()
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  _toggleForm = () => {
    let { formActive } = this.state
    formActive = !formActive
    this.setState({ formActive })
  }

  render() {
    let project = this.props.data.wpProject
    let { formActive, isMobile } = this.state

    return (
      <>
        <SEO title={he.decode(project.seo.title)} description={project.seo.metaDesc} />
        <section className='scrolling-banner'>
          { project.acf?.banner?.backgroundImage?.localFile && <img className='scrolling-banner__image' src={project.acf.banner.backgroundImage.localFile.childImageSharp.original.src} alt={project.title} /> }
          { isMobile && project.acf.banner?.mobileVideo &&
            <video className='scrolling-banner__video' ref='videobg' autoPlay playsInline muted loop>
              <source src={project.acf.banner.mobileVideo} type='video/mp4' />
            </video>
          }
          { !isMobile && project.acf.banner?.video &&
            <video className='scrolling-banner__video' ref='videobg' autoPlay playsInline muted loop>
              <source src={project.acf.banner.video} type='video/mp4' />
            </video>
          }
          <div className='scrolling-banner__title'>
            { project.acf.banner.text && <h1>{project.acf.banner.text}</h1> }
          </div>
        </section>
        <section className='project'>
          <div className='project__inner'>
            <div className='project__info'>
              { project.acf?.logo?.localFile && <img src={project.acf.logo.localFile.childImageSharp.original.src} alt={project.title} /> }
              <h3>{project.acf.subtitle}</h3>
              <div className='project__desktop'>
                <p className='project__address'>{project.acf.address}</p>
              </div>
            </div>
            <div className='project__register'>
              <div className='project__desktop'>
                <button className='btn' onClick={() => this._toggleForm()}>Register Your Interest</button>
              </div>
              <div className='project__content' dangerouslySetInnerHTML={{ __html: project.acf.content }} />
              { project.acf.stats &&
                <ul className='project__stats'>
                  { project.acf.stats.map((el, i) => (
                    <li key={i}>
                      <span>{el.number}</span>
                      <p dangerouslySetInnerHTML={{ __html: el.text }} />
                    </li>
                  )) }
                </ul>
              }
              <div className='project__mobile'>
                <p className='project__address'>{project.acf.address}</p>
                <button className='btn' onClick={() => this._toggleForm()}>Register Your Interest</button>
              </div>
            </div>
          </div>
        </section>
        <section className={`slide-out ${formActive ? 'active' : ''}`}>
          <div className='slide-out__inner'>
            <button className='slide-out__close' onClick={() => this._toggleForm()}>
              Close
            </button>
            <RegisterYourInterest slug={project.slug} title={project.title} />
          </div>
        </section>
        { project.acf.contentBlocks && project.acf.contentBlocks.map((el, i) => RenderBlock(el.fieldGroupName, el, i))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpProject(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        category
        banner {
          backgroundImage {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          video
          mobileVideo
          text
        }
        logo {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        subtitle
        suburb
        address
        content
        stats {
          number
          text
        }
        tracking
        contentBlocks {
          ... on WpProject_Acf_ContentBlocks_TwoColumnImages {
            fieldGroupName
            left {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              vimeo
              caption
            }
            right {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              vimeo
              caption
            }
          }
          ... on WpProject_Acf_ContentBlocks_ImageTitleContent {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            content
            reverse
          }
          ... on WpProject_Acf_ContentBlocks_Map {
            fieldGroupName
            googleMap {
              latitude
              longitude
            }
          }
          ... on WpProject_Acf_ContentBlocks_FourColumnText {
            fieldGroupName
            columns {
              text
            }
          }
          ... on WpProject_Acf_ContentBlocks_Gallery {
            fieldGroupName
            images {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              caption
            }
          }
          ... on WpProject_Acf_ContentBlocks_ArchTextBanner {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              altText
            }
            title
          }
        }
      }
    }
  }
`

export default ProjectTemplate
