import React, { Component } from 'react'
import Link from '../utils/link'
// import RayWhite from '../assets/images/ray-white.png'
import RayWhite2 from '../assets/images/ray-white-2.png'
import Hudson from '../assets/images/hudson-bond.png'
import crabtrees from '../assets/images/crabtrees.png'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class RegisterYourInterest extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    enquiry: '',

    otherTextField: false,
  }

  componentDidMount() {
    this.setState({ pathname: window.location.pathname })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({ ['page']: this.state.pathname })

    if (e.target.name === 'how_you_hear' && e.target.value === 'Other') {
      this.setState((prev) => {
        return {otherTextField: !prev.otherTextField};
      });
    }

    if (e.target.name === 'how_you_hear' && e.target.value !== 'Other') {
      if (this.state['how_you_hear_other'] !== null) {
        this.setState({
          how_you_hear_other: '',
          otherTextField: false,
          [e.target.name]: e.target.value,
        });
      }
    }

  }

  handleSubmit = e => {
    e.preventDefault()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail" }))
  }

  render() {
    let props = {
      ref: "form",
      name: "register_your_interest",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    if (this.state.result === "success")
      return (
        <div>
          <h4>Confirmation</h4>
          <p>Thank you for your enquiry a Found sales representative will be in touch with you shortly.</p>
          <div className='footer__social'>
            <ul>
              <li><Link to='https://www.instagram.com/found.huntingdale/'>Instagram</Link></li>
            </ul>
          </div>
        </div>
      )

    return (
      <form {...props}>
        <h4>Register your interest.</h4>

        <div className="form__row form__row--double">
          <input
            type='hidden'
            name='page'
            value=''
          />
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            onChange={this.handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Contact Email"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className='form__row form__row--select'>
          <select name='interested_in' onChange={this.handleChange} required>
            <option>Interested In</option>
            <option value='Warehouse'>Warehouse</option>
            <option value='Storage'>Storage</option>
            <option value='Warehouse + Storage'>Warehouse + Storage</option>
          </select>
        </div>

        {this.state.otherTextField &&
        <div className="form__row">
          <input
            placeholder="How did you hear about us?"
            name="how_you_hear_other"
            type="text"
            onChange={this.handleChange}
          />
        </div>}

        <div className="form__row form__row--submit">
          <button type="submit" className="btn">
            Submit
          </button>
        </div>

        <div className='agents'>
          <div className='agents__section'>
            <img src={RayWhite2} alt='Ray White' />
            <ul>
              <li>Theo Karkanis <a href='tel:+61431391035'>0431 391 035</a></li>
              <li>George Kelepouris <a href='tel:+61425798677'>0425 798 677</a></li>
              <li>George Ganavas <a href='tel:+61478634562'>0478 634 562</a></li>
            </ul>
          </div>
          <div className='agents__section'>
            <img src={Hudson} alt='Hudson' />
            <ul>
              <li>Dylan Ashby <a href='tel:+61402262151'>0402 262 151</a></li>
              <li>Mathew Dompietro <a href='tel:+61403493253'>0403 493 253</a></li>
              <li>Tom Gilbert <a href='tel:+61422585502'>0422 585 502</a></li>
            </ul>
          </div>
          <div className='agents__section'>
            <div className="agents__section__imagewrapper">
              <img className='crabtrees' src={crabtrees} alt='Crabtrees Real Estate' />
            </div>
            <ul>
              <li>Pana Kritikos <a href='tel:+61418358700'>0418 358 700</a></li>
              <li>Luke Pitcher <a href='tel:+61417055578'>0417 055 578</a></li>
            </ul>
          </div>
        </div>
      </form>
    )
  }
}

export default RegisterYourInterest
